import React from 'react'
import { withTranslation } from 'react-i18next'
import { Search } from 'semantic-ui-react'
import './NominatimSearch.css'

class NominatimSearch extends React.Component {
    state = {
        query: '',
        isLoading: false,
        results: [],
    }

    timer = null

    debounce(action, delay) {
        const context = this

        return function() {
            clearTimeout(context.timer)
            context.timer = setTimeout(() => {
                action.apply(context, arguments)
            }, delay)
        }
    }

    submitSearch = async query => {
        if (query.trim().length === 0) {
            this.setState({ isLoading: false, results: [] })
            return
        }

        try {
            this.setState({ isLoading: true, results: [] })
            const encodedQuery = encodeURIComponent(query)
            const url = `https://nominatim.openstreetmap.org/search/${encodedQuery}?format=json&addressdetails=1&bounded=1&viewbox=13.3,48.42,3.11,45.19&limit=5`
            const data = await fetch(url)
            const results = await data.json()
            this.setState({ isLoading: false, results })
        } catch (error) {
            console.error(error)
            this.setState({ isLoading: false })
        }
    }

    handleSearchChange = e => {
        const query = e.target.value
        this.setState({ query, results: [] })
        this.debounce(this.submitSearch, 500)(query)
    }

    handleResultSelect = (e, { result }) => {
        const selectedResult = this.state.results[result.id]
        this.props.onSearchResultSelect(selectedResult.boundingbox)
    }

    mapResultToDisplayResult = (result, index) => {
        const { address, display_name } = result
        const commaIndex = display_name.indexOf(',')
        const titleEndIndex = commaIndex >= 0 ? commaIndex : display_name.length
        const title = display_name.slice(0, titleEndIndex)

        const road = address.road
        const houseNumber = address.house_number
        const postcode = address.postcode
        const city = address.city || address.village || address.town
        const country = address.country

        let description = ''

        if (road) {
            description += road
            if (houseNumber) {
                description += ` ${houseNumber}`
            }
        }

        if (city) {
            if (description.length > 0) {
                description += ', '
            }
            description += (postcode ? postcode + ' ' : '') + city
        }

        if (country) {
            if (description.length > 0) {
                description += ', '
            }
            description += country
        }

        return { id: index, key: `result-${index}`, title, description }
    }

    render() {
        const { t } = this.props
        const { isLoading, query, results } = this.state
        const displayResults = results.map(this.mapResultToDisplayResult)
        return (
            <Search
                showNoResults={false}
                size="mini"
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={displayResults}
                value={query}
                placeholder={t('search-placeholder')}
            />
        )
    }
}

export default withTranslation()(NominatimSearch)
