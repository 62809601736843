import React, { Component, Fragment } from 'react'
import TopMenu from './TopMenu'
import Map, { defaultInitialLocation } from './Map'
import CastleDetailView from './CastleDetailView'
import { Sidebar, Modal, Button, Responsive } from 'semantic-ui-react'
import { fetchCastleFeatures, fetchCastleFeature, fetchLastModifiedDate, fetchFeatureInfo } from './api'
import { withTranslation } from 'react-i18next'
import PermalinkManager from './PermalinkManager'
import L from 'leaflet'
import moment from 'moment'

class App extends Component {
    constructor(props) {
        super(props)

        this.permalinkManager = new PermalinkManager()

        const permalinkData = this.permalinkManager.decodePermalink()
        const initialLocation = permalinkData.location || defaultInitialLocation
        const selectedFeatureId = permalinkData.osmID
        const selectedFeatureVisible = permalinkData.osmID != null

        this.state = {
            attribution: props.t('loading'),
            features: [],
            featuresUpdateDate: new Date(),
            selectedFeatureId,
            selectedFeatureVisible,
            selectedFeature: null,
            language: props.i18n.language,
            bounds: null,
            boundsUpdateDate: new Date(),
            initialLocation,
            currentLocation: initialLocation,
        }
    }

    componentDidMount() {
        this.updateAttributionString()
    }

    componentDidUpdate() {
        const { currentLocation, selectedFeatureId, selectedFeatureVisible } = this.state
        if (currentLocation) {
            const permalinkData = { location: currentLocation }

            if (selectedFeatureId && selectedFeatureVisible) {
                permalinkData.osmID = selectedFeatureId
            } else {
                permalinkData.osmID = null
            }

            this.permalinkManager.encodePermalink(permalinkData)
        }
    }

    async updateAttributionString() {
        let formattedDate = ''
        try {
            const date = await fetchLastModifiedDate()
            if (date != null) {
                formattedDate = moment(date).format('DD.MM.YYYY HH:mm ')
            }
        } catch (error) {
            console.error(error)
        }

        const attribution = `Data ${formattedDate}© <a href="http://opendatacommons.org/licenses/odbl/1.0/"> ODbL </a>
        | Map © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>
        | <a href="http://www.openstreetmap.org/fixthemap">Improve this map</a>`
        this.setState({ attribution })
    }

    async loadCastles(bounds) {
        try {
            const features = await fetchCastleFeatures(bounds, 100)

            let selectedFeature = this.state.selectedFeature
            const selectedFeatureId = this.state.selectedFeatureId
            if (selectedFeature == null && selectedFeatureId != null) {
                selectedFeature = await fetchCastleFeature(selectedFeatureId)
            }

            this.setState({ features, featuresUpdateDate: new Date(), selectedFeature })
        } catch {
            console.error('Error: failed to fetch castle features')
        }
    }

    handleLanguageChange = language => {
        this.props.i18n.changeLanguage(language)
        this.setState({ language })
    }

    getSelectedFeature = async (dotCoordinates, cursorLatlng) => {
        if (!dotCoordinates) {
            return null
        }

        const { tileCoordinates, dotOffset } = dotCoordinates
        try {
            const features = await fetchFeatureInfo(tileCoordinates, dotOffset)
            let closestFeature = null
            let closestDistance = Number.MAX_VALUE
            for (const feature of features) {
                const [lng, lat] = feature.geometry.coordinates
                const featureLatLng = L.latLng(lat, lng)
                const distanceToCursor = featureLatLng.distanceTo(cursorLatlng)
                if (distanceToCursor < closestDistance) {
                    closestFeature = feature
                    closestDistance = distanceToCursor
                }
            }
            return closestFeature
        } catch (error) {
            console.error(error)
            return null
        }
    }

    handleMapClick = async (dotCoordinates, cursorLatlng) => {
        const selectedFeature = await this.getSelectedFeature(dotCoordinates, cursorLatlng)
        if (selectedFeature) {
            this.setState({
                selectedFeatureVisible: true,
                selectedFeatureId: selectedFeature.id,
                selectedFeature,
            })
        } else {
            this.setState({ selectedFeatureVisible: false })
        }
    }

    handleMarkerClick = selectedFeature => {
        this.setState({ selectedFeatureVisible: true, selectedFeatureId: selectedFeature.id, selectedFeature })
    }

    handleBoundsChange = map => {
        const bounds = map.getBounds()
        this.loadCastles(bounds)
        const currentLocation = { zoom: map.getZoom(), center: map.getCenter() }
        this.setState({ currentLocation })
    }

    handleModalClose = () => {
        this.setState({ selectedFeatureVisible: false })
    }

    handleSearchResultSelect = bounds => {
        this.setState({ boundsUpdateDate: new Date(), bounds })
    }

    render() {
        const {
            attribution,
            language,
            selectedFeatureId,
            selectedFeatureVisible,
            selectedFeature,
            features,
            featuresUpdateDate,
            bounds,
            boundsUpdateDate,
            initialLocation,
            currentLocation,
        } = this.state

        const castleDetailView = (
            <CastleDetailView feature={selectedFeature} language={language.slice(0, 2).toLowerCase()} />
        )
        const mapView = (
            <Map
                attribution={attribution}
                initialLocation={initialLocation}
                currentLocation={currentLocation}
                selectedFeatureId={selectedFeatureId}
                selectedFeatureVisible={selectedFeatureVisible}
                features={features}
                featuresUpdateDate={featuresUpdateDate}
                onMarkerClick={this.handleMarkerClick}
                onMapClick={this.handleMapClick}
                onBoundsChange={this.handleBoundsChange}
                bounds={bounds}
                boundsUpdateDate={boundsUpdateDate}
            />
        )
        return (
            <Fragment>
                <TopMenu
                    languages={['de', 'en', 'fr', 'it', 'rm']}
                    selectedLanguage={language}
                    onLanguageChange={this.handleLanguageChange}
                    onSearchResultSelect={this.handleSearchResultSelect}
                />

                <Responsive as={Sidebar.Pushable} minWidth={Responsive.onlyMobile.maxWidth}>
                    <Sidebar animation="overlay" direction="right" width="very wide" visible={selectedFeatureVisible}>
                        {castleDetailView}
                    </Sidebar>
                    <Sidebar.Pusher style={{ height: '100%' }}>{mapView}</Sidebar.Pusher>
                </Responsive>

                <Responsive as="div" maxWidth={Responsive.onlyMobile.maxWidth} style={{ flex: 1 }}>
                    {mapView}
                    <Modal open={selectedFeatureVisible} onClose={this.handleModalClose}>
                        <Modal.Content scrolling>{castleDetailView}</Modal.Content>
                        <Modal.Actions>
                            <Button color="blue" onClick={this.handleModalClose}>
                                {this.props.t('close')}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Responsive>
            </Fragment>
        )
    }
}

export default withTranslation()(App)
