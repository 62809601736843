export default class OSMDetails {
    constructor(feature) {
        this._extractOSMDetails(feature)
    }

    _extractOSMDetails(feature) {
        const properties = feature.properties

        this.name = properties.name

        if (feature.id != null) {
            this.osmID = feature.id
        }

        if (properties.alt_name != null) {
            this.alternativeName = properties.alt_name
        }

        if (properties.castle_type != null) {
            this.type = properties.castle_type
        }

        this.isRuin = properties.ruins === 'yes'

        if (properties.website != null) {
            this.website = properties.website
        }

        if (properties.email != null) {
            this.email = properties.email
        }

        if (properties.opening_hours != null) {
            this.opening_hours = properties.opening_hours
        }

        if (properties.access != null) {
            this.access = properties.access
        }

        if (properties.wheelchair === 'yes' || properties.wheelchair === 'limited' || properties.wheelchair === 'no') {
            this.wheelchair = properties.wheelchair
        }

        const address = this._extractAddress(properties)
        if (address != null) {
            this.address = address
        }

        if (properties.wikidata != null) {
            this.wikidata = properties.wikidata
        }

        const geometry = feature.geometry

        if (geometry.coordinates != null) {
            this.coordinates = geometry.coordinates
        }
    }

    _extractAddress(properties) {
        if (
            properties['addr:street'] != null ||
            properties['addr:housenumber'] != null ||
            properties['addr:postcode'] != null ||
            properties['addr:city'] != null ||
            properties['addr:country'] != null ||
            properties['addr:full'] != null
        ) {
            const address = {}

            if (properties['addr:full'] != null) {
                address.full = properties['addr:full']
            } else {
                if (properties['addr:street'] != null) {
                    address.street = properties['addr:street']
                }

                if (properties['addr:housenumber'] != null) {
                    address.housenumber = properties['addr:housenumber']
                }

                if (properties['addr:postcode'] != null) {
                    address.postalCode = properties['addr:postcode']
                }

                if (properties['addr:city'] != null) {
                    address.city = properties['addr:city']
                }

                if (properties['addr:country'] != null) {
                    address.country = properties['addr:country']
                }
            }

            return address
        } else {
            return null
        }
    }

    getOSMLink() {
        const osmID = this.osmID
        if (!osmID) {
            return ''
        }

        if (osmID.startsWith('N')) {
            return `https://www.openstreetmap.org/node/${osmID.slice(1)}`
        } else if (osmID.startsWith('W')) {
            return `https://www.openstreetmap.org/way/${osmID.slice(1)}`
        } else {
            return `https://www.openstreetmap.org/relation/${osmID.slice(1)}`
        }
    }

    getMangroveReviewLink() {
        let mangroveQuery = 'https://mangrove.reviews/search?sub=geo:'
        return `${mangroveQuery}${this.coordinates[1]},${this.coordinates[0]}?q=${this.name}%26u%3D30`
    }
}
