import React, { useState } from 'react'
import { Icon, Button, Popup, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { getAddressString } from './OSMDetail'
import moment from 'moment'
import './SharePopup.css'

function constructFacebookShareLink() {
    const url = window.location.href
    const encodedURL = encodeURIComponent(url)
    return `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`
}

function constructTwitterShareLink(props, t) {
    const { osmDetails, wikiDataInfo } = props
    const castleName = (wikiDataInfo.data && wikiDataInfo.data.label) || osmDetails.name || t('unknown-name')
    const text = `${castleName} - ${t('page-title')}`
    const url = window.location.href
    const encodedText = encodeURIComponent(text)
    const encodedURL = encodeURIComponent(url)
    return `https://twitter.com/intent/tweet/?text=${encodedText}&url=${encodedURL}`
}

function openPopupWindow(url, width, height) {
    const left = global.screen.width / 2 - width / 2
    const top = global.screen.height / 2 - height / 2

    window.open(
        url,
        '',
        `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`
    )
}

function createSummary(feature, osmDetails, featureTypeInfo, wikiDataInfo, t) {
    const geometry = feature.geometry
    let coordinates = null
    if (geometry && geometry.type === 'Point') {
        const [latitude, longitude] = geometry.coordinates
        coordinates = `${longitude.toFixed(6)}, ${latitude.toFixed(6)}`
    }
    const label = wikiDataInfo.data && wikiDataInfo.data.label
    const imageURL = wikiDataInfo.data && wikiDataInfo.data.imageURL
    const commonsCategory = wikiDataInfo.data && wikiDataInfo.data.commonsCategory
    const wikipediaEntry = wikiDataInfo.data && wikiDataInfo.data.wikipediaEntry
    const inceptionDate = wikiDataInfo.data && wikiDataInfo.data.inceptionDate

    let summary = `${label || osmDetails.name || t('unknown-name')}\n`
    if (wikipediaEntry) {
        summary += `\n${wikipediaEntry.summary} (Wikipedia: ${wikipediaEntry.url})\n`
    }

    summary += '\n'

    const tags = [
        'castle_type',
        'Coordinates',
        'Image',
        'Commons Category',
        'addr:*',
        'website',
        'email',
        'opening_hours',
        'access',
        'wheelchair',
    ]
    const values = [
        featureTypeInfo.getDescription(t),
        coordinates,
        imageURL,
        commonsCategory && commonsCategory.url,
        osmDetails.address && getAddressString(osmDetails.address),
        osmDetails.website,
        osmDetails.email,
        osmDetails.opening_hours,
        osmDetails.access,
        osmDetails.wheelchair,
    ]

    for (let i = 0; i < tags.length; i++) {
        const tag = tags[i]
        const value = values[i]
        if (value) {
            summary += `${tag}: ${value}\n`
        }
    }

    if (inceptionDate) {
        summary += `inception: ${moment(inceptionDate).format('YYYY')}\n`
    }

    summary += '\n'
    summary += `OpenStreetMap: ${osmDetails.getOSMLink()}\n`

    if (wikiDataInfo.data) {
        summary += `Wikidata: ${wikiDataInfo.data.getWikiDataLink()}\n`
    }

    if (wikipediaEntry) {
        summary += `Wikipedia: ${wikipediaEntry.url}\n`
    }

    summary += `\nPermalink: ${window.location.href}`
    return summary
}

function createTextArea(text) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.position = 'fixed'
    textArea.style.top = 0
    textArea.style.left = 0
    textArea.style.width = '2em'
    textArea.style.height = '2em'
    textArea.style.padding = 0
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'
    textArea.style.background = 'transparent'
    return textArea
}

function selectText(textArea) {
    const isIOS = navigator.userAgent.match(/ipad|iphone/i)
    if (isIOS) {
        const range = document.createRange()
        range.selectNodeContents(textArea)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        textArea.setSelectionRange(0, 999999)
    } else {
        textArea.focus()
        textArea.select()
    }
}

function copyText() {
    try {
        if (!document.execCommand('copy')) {
            console.error('copying failed')
        }
    } catch (error) {
        console.error(error)
    }
}

// adapted from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
// and from https://gist.github.com/rproenca/64781c6a1329b48a455b645d361a9aa3
function writeTextToClipboard(text) {
    const textArea = createTextArea(text)
    document.body.appendChild(textArea)
    selectText(textArea)
    copyText()
    document.body.removeChild(textArea)
}

export default function SharePopup(props) {
    const { feature, osmDetails, featureTypeInfo, wikiDataInfo } = props
    const { t } = useTranslation()
    const facebookShareLink = constructFacebookShareLink()
    const twitterShareLink = constructTwitterShareLink(props, t)
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Popup
            trigger={<Icon name="share alternate" size="large" className="share-popup-trigger" />}
            on="click"
            position="bottom center"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
        >
            <Header>{t('share')}</Header>
            <div style={{ display: 'flex', marginBottom: 10 }}>
                <Button
                    fluid
                    size="small"
                    color="facebook"
                    onClick={() => {
                        openPopupWindow(facebookShareLink, 500, 300)
                        setIsOpen(false)
                    }}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Icon name="facebook" />
                    Facebook
                </Button>
                <Button
                    fluid
                    size="small"
                    color="twitter"
                    onClick={() => {
                        openPopupWindow(twitterShareLink, 500, 300)
                        setIsOpen(false)
                    }}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Icon name="twitter" />
                    Twitter
                </Button>
            </div>
            <Button
                fluid
                size="small"
                onClick={() => {
                    writeTextToClipboard(window.location.href)
                    setIsOpen(false)
                }}
                style={{ whiteSpace: 'nowrap', marginBottom: 10 }}
            >
                <Icon name="clipboard list" />
                {t('copy-url')}
            </Button>
            <Button
                disabled={wikiDataInfo.isLoading}
                fluid
                size="small"
                onClick={() => {
                    writeTextToClipboard(createSummary(feature, osmDetails, featureTypeInfo, wikiDataInfo, t))
                    setIsOpen(false)
                }}
                style={{ whiteSpace: 'nowrap' }}
            >
                <Icon name="clipboard list" />
                {t('copy-text')}
            </Button>
        </Popup>
    )
}
