import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export default function PreviewText(props) {
    const { text, wordLimit, link } = props
    const { t } = useTranslation()

    if (text == null) {
        return null
    }

    return (
        <Fragment>
            <p className="summary">
                {truncate(text, wordLimit, '... ')}
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {t('read-more')}
                </a>
                <sup>&nbsp;3</sup>
            </p>
        </Fragment>
    )
}

function truncate(text, limit, after) {
    // Make sure an element and number of items to truncate is provided
    if (!text || !limit) return

    // Get the inner content of the element
    let content = text.trim()

    // Convert the content into an array of words
    // Remove any words above the limit
    content = content.split(' ').slice(0, limit)

    // Convert the array of words back into a string
    // If there's content to add after it and the content is equal the limit, add it
    content = content.join(' ') + (after && content.length === limit ? after : ' ')

    return content
}
