import React from 'react'
import { Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import FeatureTypeInfo from '../FeatureTypeInfo'
import OSMDetails from '../OSMDetails'
import './CastleMarker.css'
import { useTranslation } from 'react-i18next'

export default function(props) {
    const { feature, onClick, isSelected } = props
    const { t } = useTranslation()
    const osmDetails = new OSMDetails(feature)
    const featureTypeInfo = new FeatureTypeInfo(feature)
    const markerIcon = L.divIcon({
        className: isSelected ? 'marker-icon marker-icon-selected' : 'marker-icon',
        iconAnchor: [50, 13],
        iconSize: [100, 50],
        html: `
          <div><img src="${featureTypeInfo.getIcon(isSelected)}"></div>
          <div>${osmDetails.name || t('unknown-name')}</div>
          `,
    })
    const [lng, lat] = feature.geometry.coordinates
    return <Marker position={[lat, lng]} icon={markerIcon} onclick={onClick} />
}
