import React, { useState, useEffect } from 'react'
import { Header, Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import './OptionalImage.css'

export default function(props) {
    const [imageLoadingResult, setImageLoadingResult] = useState(null)
    const { isLoading, imageURL, alt } = props
    const isLoadingImage = !isLoading && imageURL != null && imageLoadingResult == null

    useEffect(() => {
        setImageLoadingResult(null)
    }, [imageURL])

    const { t } = useTranslation()

    return (
        <div className="optional-image">
            {(isLoading || isLoadingImage) && <Loader active inverted inline />}
            {!isLoading && imageURL != null && (
                <img
                    className="optional-image-img"
                    style={{ display: isLoadingImage || imageLoadingResult === 'error' ? 'none' : 'block' }}
                    src={imageURL}
                    alt={alt}
                    onLoad={() => setImageLoadingResult('success')}
                    onError={() => setImageLoadingResult('error')}
                />
            )}
            {!isLoading && imageURL == null && <Header inverted>{t('no-image-available')}</Header>}
            {!isLoading && imageURL != null && imageLoadingResult === 'error' && (
                <Header inverted>{t('image-loading-error')}</Header>
            )}
        </div>
    )
}
