import React from 'react'
import { Menu, Header, Dropdown, Responsive } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MenuIconTower } from './images/MenuIconTower.svg'
import NominatimSearch from './NominatimSearch'

export default function (props) {
    const { languages, selectedLanguage, onLanguageChange } = props
    const { t } = useTranslation()
    return (
        <Menu
            attached="top"
            inverted={true}
            size="large"
            style={{ flex: 0, backgroundColor: '#9600AE', borderRadius: 0 }}
            borderless
        >
            <Menu.Item as={Menu.Item} name="editorials" link href="/">
                <MenuIconTower />
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Header size="small" style={{ color: 'white', fontSize: 20, marginLeft: 15 }}>
                        {t('page-title')}
                        <Header.Subheader style={{ color: '#eeeeee', fontSize: 11 }}>
                            by Geometa Lab OST
                        </Header.Subheader>
                    </Header>
                </Responsive>
            </Menu.Item>

            <Menu.Menu position="right">
                <Menu.Item>
                    <NominatimSearch onSearchResultSelect={props.onSearchResultSelect} />
                </Menu.Item>

                <Menu.Item link as="a" href="https://wiki.openstreetmap.org/wiki/Burgen-Dossier_Schweiz">
                    {t('about')}
                </Menu.Item>
                <Dropdown item text={selectedLanguage.slice(0, 2).toUpperCase()}>
                    <Dropdown.Menu style={{ zIndex: 9999 }}>
                        {languages.map((language, index) => (
                            <Dropdown.Item key={`language-${index}`} onClick={() => onLanguageChange(language)}>
                                {language.slice(0, 2).toUpperCase()}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    )
}
