import React from 'react'
import Control from 'react-leaflet-control'
import './LayerSelectionControl.css'

export default function LayerSelectionControl(props) {
    const { titles, images, selectedIndex } = props

    return (
        <Control position="topleft">
            <div className="layer-selection-control">
                {titles.map((title, index) => (
                    <div
                        key={`layer-selector-${index}`}
                        className={selectedIndex === index ? 'layer-selector layer-selector-active' : 'layer-selector'}
                        style={{ backgroundImage: `url(${images[index]})` }}
                        title={title}
                        onClick={() => props.onSelectLayer(index)}
                    />
                ))}
            </div>
        </Control>
    )
}
