import React, { useState, useEffect } from 'react'
import './index.css'
import { Header, List, Icon, Button } from 'semantic-ui-react'
import FeatureTypeInfo from '../FeatureTypeInfo'
import WikiDataInfo from '../WikiDataInfo'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import OSMDetails from '../OSMDetails'
import { OSMDetail, Address, OpeningHours, Website, Email, Wheelchair, Access } from './OSMDetail'
import PreviewText from './PreviewText'
import OptionalImage from './OptionalImage'
import Link from './Link'
import SharePopup from './SharePopup'

export default function(props) {
    if (props.feature == null) {
        return null
    }

    const { t } = useTranslation()

    const [wikiDataInfo, setWikiDataInfo] = useState({ isLoading: false, data: null })
    const wikidataId = props.feature.properties.wikidata

    useEffect(() => {
        let isSelected = true
        async function fetchData() {
            setWikiDataInfo({ isLoading: true, data: null })

            const data = new WikiDataInfo(wikidataId, props.language)
            await data.fetch()

            if (isSelected) {
                setWikiDataInfo({ isLoading: false, data })
            }
        }

        if (wikidataId != null) {
            fetchData()
        } else {
            setWikiDataInfo({ isLoading: false, data: null })
        }

        return () => {
            isSelected = false
        }
    }, [wikidataId, props.language])

    const osmDetails = new OSMDetails(props.feature)
    const featureTypeInfo = new FeatureTypeInfo(props.feature)
    const isLoading = wikiDataInfo.isLoading
    const imageURL = wikiDataInfo.data && wikiDataInfo.data.imageURL
    const label = wikiDataInfo.data && wikiDataInfo.data.label
    const wikipediaEntry = wikiDataInfo.data && wikiDataInfo.data.wikipediaEntry
    const inceptionDate = wikiDataInfo.data && wikiDataInfo.data.inceptionDate
    const commonsCategory = wikiDataInfo.data && wikiDataInfo.data.commonsCategory
    const languagesAvailable = wikiDataInfo.data && wikiDataInfo.data.languagesAvailable

    return (
        <div className="castle-detail-view">
            <div className="castle-detail-view-content">
                <div
                    style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
                >
                    <Header as="h2" style={{ fontSize: 20 }}>
                        {isLoading && t('loading')}
                        {!isLoading && (label || osmDetails.name || t('unknown-name'))}
                        {label != null && osmDetails.name != null && label !== osmDetails.name && (
                            <Header.Subheader style={{ fontSize: 16 }}>{osmDetails.name}</Header.Subheader>
                        )}
                        {(label == null || osmDetails.name == null || label === osmDetails.name) && (
                            <Header.Subheader>&nbsp;</Header.Subheader>
                        )}
                    </Header>
                    <SharePopup
                        feature={props.feature}
                        osmDetails={osmDetails}
                        featureTypeInfo={featureTypeInfo}
                        wikiDataInfo={wikiDataInfo}
                    />
                </div>
                <OptionalImage isLoading={isLoading} imageURL={imageURL} alt={osmDetails.name} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 15,
                        marginBottom: 15,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={featureTypeInfo.getIcon()}
                            style={{ width: 16, height: 16, marginRight: 5 }}
                            alt="Castle Type"
                        />
                        <span>{featureTypeInfo.getDescription(t)}</span>
                        <sup>&nbsp;1</sup>
                    </div>
                    {commonsCategory && (
                        <div>
                            <a href={commonsCategory.url} target="_blank" rel="noopener noreferrer">
                                <Icon name="images" />
                                {t('more-pictures')}
                            </a>
                            <sup>&nbsp;2</sup>
                        </div>
                    )}
                </div>
                {wikipediaEntry && (
                    <PreviewText text={wikipediaEntry.summary} wordLimit={90} link={wikipediaEntry.url} />
                )}
                {!isLoading && !wikipediaEntry && <i>{t('no-wikipedia-entry')}</i>}
                {!isLoading && !wikipediaEntry && languagesAvailable && languagesAvailable.length !== 0 &&
                 <i><br />{t('alternative-languages')} {languagesAvailable.join(', ').toUpperCase()}</i>
                }
                <List relaxed>
                    <OSMDetail
                        icon="marker"
                        value={osmDetails.address}
                        tag="addr:*"
                        render={value => <Address address={value} />}
                    />

                    <OSMDetail
                        icon="globe"
                        value={osmDetails.website}
                        tag="website"
                        render={value => <Website website={value} />}
                    />

                    <OSMDetail
                        icon="mail"
                        value={osmDetails.email}
                        tag="email"
                        render={value => <Email email={value} />}
                    />

                    <OSMDetail
                        icon="clock outline"
                        value={osmDetails.opening_hours}
                        tag="opening_hours"
                        render={value => <OpeningHours opening_hours={value} />}
                    />

                    <OSMDetail
                        icon="arrow alternate circle up outline"
                        value={osmDetails.access}
                        tag="access"
                        render={value => <Access access={value} />}
                    />

                    <OSMDetail
                        icon="wheelchair"
                        value={osmDetails.wheelchair}
                        tag="wheelchair"
                        render={value => <Wheelchair wheelchair={value} />}
                    />

                    {inceptionDate && (
                        <List.Item>
                            <List.Icon name="chess rock" />
                            <List.Content>
                                {moment(inceptionDate).format('YYYY')}
                                <sup>&nbsp;2</sup>
                            </List.Content>
                        </List.Item>
                    )}
                </List>
                <br />
                <center>
                    <Button
                        positive
                        size="small"
                        basic
                        style={{ textDecoration: 'none' }}
                        href={osmDetails.getMangroveReviewLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('add-tags')}
                    </Button>
                </center>
            </div>
            <div className="castle-detail-view-bottom-link-row">
                <Link title="OpenStreetMap" url={osmDetails.getOSMLink()} index={1} />
                <Icon name="circle" size="mini" style={{ marginLeft: 8, marginRight: 8 }} />
                <Link title="Wikidata" url={wikiDataInfo.data && wikiDataInfo.data.getWikiDataLink()} index={2} />
                <Icon name="circle" size="mini" style={{ marginLeft: 8, marginRight: 8 }} />
                <Link title="Wikipedia" url={wikipediaEntry && wikipediaEntry.url} index={3} />
            </div>
        </div>
    )
}
