export default class PermalinkManager {
    decodePermalink() {
        const hash = window.location.hash.replace('#', '')
        const parts = hash.split(',')

        if (parts.length !== 3 && parts.length !== 4) {
            return {}
        }

        const data = {}

        data.location = {
            zoom: parseInt(parts[2].slice(0, -1), 10),
            center: {
                lat: parseFloat(parts[0]),
                lng: parseFloat(parts[1]),
            },
        }

        if (parts.length === 4) {
            data.osmID = parts[3]
        }

        return data
    }

    encodePermalink(data) {
        if (data.location) {
            const location = data.location
            const lat = Math.round(location.center.lat * 100000) / 100000
            const lng = Math.round(location.center.lng * 100000) / 100000
            let hash = `#${lat},${lng},${location.zoom}z`
            if (data.osmID) {
                hash += `,${data.osmID}`
            }
            window.location.hash = hash
        }
    }
}
