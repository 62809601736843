import React, { Fragment } from 'react'

export default function(props) {
    const { url, title, index } = props

    return (
        <Fragment>
            <sup>{index}&nbsp;</sup>
            {url && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {title}
                </a>
            )}
            {!url && title}
        </Fragment>
    )
}
