import wdk from 'wikidata-sdk'
import moment from 'moment'
import md5 from 'md5'

export default class WikiDataInfo {
    constructor(wikidataId, language) {
        this.wikidataId = wikidataId
        this.language = language
    }

    async fetch() {
        const url = wdk.getEntities(this.wikidataId)
        const response = await fetch(url)
        const json = await response.json()
        const data = wdk.simplify.entities(json.entities, { timeConverter: 'simple-day', addUrl: true })
        const wikidataData = data[this.wikidataId]
        const claims = wikidataData.claims

        if (claims.P18 != null && claims.P18.length > 0) {
            const imageName = claims.P18[0]
            const imageURL = getImageURL(imageName)
            this.imageURL = imageURL
        }

        if (claims.P856 != null && claims.P856.length > 0) {
            const officialWebsite = claims.P856[0]
            this.officialWebsite = officialWebsite
        }

        if (claims.P571 != null && claims.P571.length > 0) {
            const inceptionDateString = claims.P571[0]
            const inceptionDate = moment(inceptionDateString, ['YYYY', 'YYYY-MM', 'YYYY-MM-DD'])
            if (inceptionDate.isValid()) {
                this.inceptionDate = inceptionDate.toDate()
            }
        }

        if (claims.P373 != null && claims.P373.length > 0) {
            const commonsCategory = claims.P373[0].replace(new RegExp(' ', 'g'), '_')
            this.commonsCategory = {
                name: commonsCategory,
                url: `https://commons.wikimedia.org/wiki/Category:${commonsCategory}`,
            }
        }

        if (wikidataData.labels) {
            let label = wikidataData.labels[this.language]
            this.label = label
        }

        if (wikidataData.sitelinks) {
            let wiki = wikidataData.sitelinks[`${this.language}wiki`]

            if (wiki) {
                try {
                    const { title, url } = wiki
                    const summary = await loadWikipediaSummary(title, this.language)
                    this.wikipediaEntry = {
                        url: url,
                        summary: summary,
                    }
                } catch (error) {
                    console.error('Error: failed to load wikipedia summary')
                }
            } else {
                const languages = ['de', 'en', 'fr', 'it', 'rm']
                this.languagesAvailable = languages.filter(language => `${language}wiki` in wikidataData.sitelinks)

            }
        }
    }

    getWikiDataLink() {
        return `https://www.wikidata.org/wiki/${this.wikidataId}`
    }
}

async function loadWikipediaSummary(siteTitle, language) {
    const cleanSiteTitle = siteTitle.replace(new RegExp(' ', 'g'), '_')
    const url = `https://${language}.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&titles=${cleanSiteTitle}&origin=*`

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
        },
    })
    const data = await response.json()
    const pages = data.query.pages
    const keys = Object.keys(pages)
    if (keys.length > 0) {
        const firstPage = pages[keys[0]]
        return firstPage.extract
    } else {
        throw new Error('no summary found')
    }
}

function getImageURL(imageName) {
    const cleanImageName = imageName.replace(new RegExp(' ', 'g'), '_')
    const md5hash = md5(cleanImageName)
    const seg1 = md5hash.slice(0, 1)
    const seg2 = md5hash.slice(0, 2)
    const seg3 = cleanImageName
    const imageURL = `https://upload.wikimedia.org/wikipedia/commons/thumb/${seg1}/${seg2}/${seg3}/640px-${seg3}`
    if (imageURL.toLowerCase().endsWith('.tif') || imageURL.toLowerCase().endsWith('.tif')) {
        return imageURL + '.jpg'
    } else {
        return imageURL
    }
}
