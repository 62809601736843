import L from 'leaflet'

export async function fetchFeatureInfo(tileCoordinates, relativeOffset) {
    const featureInfoCollection = await fetchFeatureInfoCollection(tileCoordinates, relativeOffset)
    return featureInfoCollection.features.map(adaptFeature)
}

async function fetchFeatureInfoCollection(tileCoordinates, relativeOffset) {
    const url = `https://castle-miniwfs.geoh.infs.ch/tiles/castles/${tileCoordinates.z}/${tileCoordinates.x}/${
        tileCoordinates.y
    }/${relativeOffset.x}/${relativeOffset.y}.geojson`
    return (await fetch(url)).json()
}

export async function fetchLastModifiedDate() {
    const url = 'https://castle-cron.geoh.infs.ch/osm-castles-CH.geojson'
    const response = await fetch(url, { method: 'HEAD' })
    for (const [key, value] of response.headers) {
        if (key === 'last-modified') {
            return new Date(value)
        }
    }
    return null
}

export async function fetchCastleFeature(featureId) {
    const url = `https://castle-miniwfs.geoh.infs.ch/collections/castles/items/${featureId}`
    const feature = await (await fetch(url)).json()
    return adaptFeature(feature)
}

export async function fetchCastleFeatures(bounds, limit) {
    const castleFeatureCollection = await fetchCastleFeatureCollection(bounds, limit)
    return castleFeatureCollection.features.map(adaptFeature)
}

async function fetchCastleFeatureCollection(bounds, limit) {
    const northEast = bounds.getNorthEast()
    const southWest = bounds.getSouthWest()
    const url = `https://castle-miniwfs.geoh.infs.ch/collections/castles/items?bbox=${northEast.lng},${
        northEast.lat
    },${southWest.lng},${southWest.lat}&limit=${limit}`
    return (await fetch(url)).json()
}

function adaptFeature(feature) {
    const updatedFeature = { ...feature }
    if (feature.geometry.type === 'Polygon') {
        const polygon = L.polygon(feature.geometry.coordinates)
        const center = polygon.getBounds().getCenter()
        const geometry = {
            coordinates: [center.lat, center.lng],
            type: 'Point',
        }
        updatedFeature.geometry = geometry
    }
    return updatedFeature
}
