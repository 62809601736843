import React, { Fragment } from 'react'
import { List } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export function getAddressString(address) {
    if (address.full != null) {
        return address.full
    }

    let addressStr = ''
    if (address.street != null) {
        addressStr += address.street
    }

    if (address.housenumber != null) {
        addressStr += ` ${address.housenumber}`
    }

    if (address.postalCode != null) {
        if (addressStr.length > 0) {
            addressStr += ', '
        }
        addressStr += address.postalCode
    }

    if (address.city != null) {
        if (addressStr.length > 0) {
            if (address.postalCode == null) {
                addressStr += ', '
            } else {
                addressStr += ' '
            }
        }
        addressStr += address.city
    }

    if (address.country != null) {
        if (addressStr.length > 0) {
            addressStr += ', '
        }
        addressStr += address.country
    }
    return addressStr
}

export function OSMDetail(props) {
    const { icon, value, tag, render } = props

    if (value == null) {
        return null
    }

    return (
        <List.Item>
            <List.Icon name={icon} title={`Tag: ${tag}`} />
            <List.Content>{render(value)}</List.Content>
        </List.Item>
    )
}

export function Address(props) {
    const { address } = props
    const addressStr = getAddressString(address)

    return (
        <Fragment>
            {addressStr}
            <sup>&nbsp;1</sup>
        </Fragment>
    )
}

export function Website(props) {
    const { website } = props
    return (
        <Fragment>
            <a href={website} target="_blank" rel="noopener noreferrer">
                {website}
            </a>
            <sup>&nbsp;1</sup>
        </Fragment>
    )
}

export function Email(props) {
    const { email } = props
    return (
        <Fragment>
            <a href={`mailto:${email}`}>{email}</a>
            <sup>&nbsp;1</sup>
        </Fragment>
    )
}

export function OpeningHours(props) {
    const { opening_hours } = props
    return (
        <Fragment>
            {opening_hours}
            <sup>&nbsp;1</sup>
        </Fragment>
    )
}

export function Access(props) {
    const { access } = props
    return (
        <Fragment>
            {access}
            <sup>&nbsp;1</sup>
        </Fragment>
    )
}

export function Wheelchair(props) {
    const { wheelchair } = props
    const { t } = useTranslation()
    return (
        <Fragment>
            {wheelchair === 'yes' && t('fully-wheelchair-accessible')}
            {wheelchair === 'limited' && t('partially-wheelchair-accessible')}
            {wheelchair === 'no' && t('not-wheelchair-accessible')}
            <sup>&nbsp;1</sup>
        </Fragment>
    )
}
