import castleIcon from './images/Castle.svg'
import castleSelectedIcon from './images/Castle-Selected.svg'
import castleRuinIcon from './images/CastleRuin.svg'
import castleRuinSelectedIcon from './images/CastleRuin-Selected.svg'
import fortressIcon from './images/Fortress.svg'
import fortressSelectedIcon from './images/Fortress-Selected.svg'
import fortressRuinIcon from './images/FortressRuin.svg'
import fortressRuinSelectedIcon from './images/FortressRuin-Selected.svg'
import manorIcon from './images/Manor.svg'
import manorSelectedIcon from './images/Manor-Selected.svg'
import manorRuinIcon from './images/ManorRuin.svg'
import manorRuinSelectedIcon from './images/ManorRuin-Selected.svg'
import palaceIcon from './images/Palace.svg'
import palaceSelectedIcon from './images/Palace-Selected.svg'
import palaceRuinIcon from './images/PalaceRuin.svg'
import palaceRuinSelectedIcon from './images/PalaceRuin-Selected.svg'
import towerIcon from './images/Tower.svg'
import towerSelectedIcon from './images/Tower-Selected.svg'
import towerRuinIcon from './images/TowerRuin.svg'
import towerRuinSelectedIcon from './images/TowerRuin-Selected.svg'
import archaeologicalSiteIcon from './images/ArchaeologicalSite.svg'
import archaeologicalSiteSelectedIcon from './images/ArchaeologicalSite-Selected.svg'

// based on https://wiki.openstreetmap.org/wiki/DE:Key:castle_type
export default class FeatureTypeInfo {
    constructor(feature) {
        this.feature = feature
    }

    isRuin() {
        return this.feature.properties.ruins === 'yes'
    }

    getHistoric() {
        return this.feature.properties.historic
    }

    getIcon(isSelected) {
        const historic = this.getHistoric()
        if (historic === 'castle') {
            return this.getCastleIcon(isSelected)
        } else if (historic === 'tower') {
            return this.getTowerIcon(isSelected)
        } else if (historic === 'archaeological_site') {
            return this.getArchaeologicalSiteIcon(isSelected)
        } else {
            return null
        }
    }

    getCastleIcon(isSelected) {
        const castleType = this.feature.properties.castle_type
        const isRuin = this.isRuin()

        switch (castleType) {
            case 'defensive':
            case 'fortress':
            case 'castrum':
            case 'shiro':
            case 'kremlin':
                if (isRuin) {
                    return isSelected ? fortressRuinSelectedIcon : fortressRuinIcon
                } else {
                    return isSelected ? fortressSelectedIcon : fortressIcon
                }
            case 'palace':
            case 'stately':
                if (isRuin) {
                    return isSelected ? palaceRuinSelectedIcon : palaceRuinIcon
                } else {
                    return isSelected ? palaceSelectedIcon : palaceIcon
                }
            case 'manor':
                if (isRuin) {
                    return isSelected ? manorRuinSelectedIcon : manorRuinIcon
                } else {
                    return isSelected ? manorSelectedIcon : manorIcon
                }
            default:
                if (isRuin) {
                    return isSelected ? castleRuinSelectedIcon : castleRuinIcon
                } else {
                    return isSelected ? castleSelectedIcon : castleIcon
                }
        }
    }

    getTowerIcon(isSelected) {
        const isRuin = this.isRuin()
        if (isRuin) {
            return isSelected ? towerRuinSelectedIcon : towerRuinIcon
        } else {
            return isSelected ? towerSelectedIcon : towerIcon
        }
    }

    getArchaeologicalSiteIcon(isSelected) {
        return isSelected ? archaeologicalSiteSelectedIcon : archaeologicalSiteIcon
    }

    getDescription(t) {
        return `${this._getTypeDescription(t)}${this._getRuinDescription(t) || ''}`
    }

    _getTypeDescription(t) {
        const historic = this.getHistoric()
        if (historic === 'castle') {
            const castleType = this.feature.properties.castle_type
            if (castleType != null && castleType !== '') {
                return t(castleType)
            } else {
                return t('add-tag', { tag: 'castle_type' })
            }
        } else if (historic === 'tower') {
            return t('tower')
        } else if (historic === 'archaeological_site') {
            return t('archaeological_site')
        } else {
            return null
        }
    }

    _getRuinDescription(t) {
        if (this.isRuin()) {
            return ` (${t('ruin')})`
        } else {
            return null
        }
    }
}
