import React from 'react'
import { Map as LeafletMap, TileLayer, ZoomControl } from 'react-leaflet'
import DumbControl from "react-leaflet-control"
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import './index.css'
import { ReactComponent as MaptilerLogo } from '../images/sponsors/maptiler-logo.svg'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import CastleMarker from './CastleMarker'
import rbush from 'rbush'
import PreviewMapTilerPositron from '../images/Preview-MapTilerPositron.png'
import PreviewOSMSwissStyle from '../images/Preview-OSMSwissStyle.png'
import LayerSelectionControl from './LayerSelectionControl'

/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetinaUrl,
    iconUrl: iconUrl,
    shadowUrl: shadowUrl,
})

const southWest = L.latLng(45.19, 3.11)
const northEast = L.latLng(48.42, 13.3)
const switzerlandBounds = L.latLngBounds(southWest, northEast)
export const defaultInitialLocation = {
    zoom: 8,
    center: switzerlandBounds.getCenter(),
}

export default class extends React.Component {
    constructor(props) {
        super(props)
        this.mapRef = React.createRef()
        this.state = {
            nonOverlappingFeatures: [],
            selectedLayer: 0,
        }
    }

    componentDidMount() {
        this.mapRef.current.leafletElement.attributionControl.setPrefix(false)
    }

    componentDidUpdate(oldProps) {
        const { boundsUpdateDate: oldBoundsUpdateDate, featuresUpdateDate: oldFeaturesUpdateDate } = oldProps
        const {
            boundsUpdateDate,
            featuresUpdateDate,
            bounds,
            features,
            currentLocation: { zoom },
        } = this.props
        const boundsDidUpdate = !(
            oldBoundsUpdateDate != null &&
            boundsUpdateDate != null &&
            oldBoundsUpdateDate.getTime() === boundsUpdateDate.getTime()
        )

        if (boundsDidUpdate) {
            const southWest = L.latLng(bounds[0], bounds[2])
            const northEast = L.latLng(bounds[1], bounds[3])
            const latLngBounds = L.latLngBounds(southWest, northEast)
            this.mapRef.current.leafletElement.fitBounds(latLngBounds, { maxZoom: 14 })
        }

        const featuresDidUpdate = !(
            oldFeaturesUpdateDate != null &&
            featuresUpdateDate != null &&
            oldFeaturesUpdateDate.getTime() === featuresUpdateDate.getTime()
        )

        if (featuresDidUpdate) {
            const nonOverlappingFeatures = zoom >= 19 ? features : this.removeOverlappingFeatures(features)
            this.setState({ nonOverlappingFeatures })
        }
    }

    removeOverlappingFeatures(features) {
        if (!this.mapRef.current || !this.mapRef.current.leafletElement) {
            return features
        }

        const tree = rbush()
        const nonOverlappingFeatures = []
        const map = this.mapRef.current.leafletElement

        for (const feature of features) {
            const [lng, lat] = feature.geometry.coordinates
            const containerPoint = map.latLngToLayerPoint(L.latLng(lat, lng))
            const { x, y } = containerPoint
            const xMargin = 80
            const yMargin = 50
            if (!tree.collides({ minX: x - xMargin, minY: y - yMargin, maxX: x + xMargin, maxY: y + yMargin })) {
                nonOverlappingFeatures.push(feature)
                const entry = {
                    minX: x,
                    minY: y,
                    maxX: x,
                    maxY: y,
                }
                tree.insert(entry)
            }
        }
        return nonOverlappingFeatures
    }

    getDotLayer(map) {
        for (const layer of Object.values(map._layers)) {
            if (layer._url === 'https://castle-miniwfs.geoh.infs.ch/tiles/castles/{z}/{x}/{y}.png') {
                return layer
            }
        }
        return null
    }

    // Returns the tile coordinates and the relative offset of the dot that is closest to the current
    // cursor position. Returns null if no dot is found within the given window size.
    getDotCoordinates(cursorLatlng, map, windowSize) {
        const dotLayer = this.getDotLayer(map)
        if (!dotLayer) {
            return null
        }

        const tileSize = dotLayer.getTileSize()
        const point = map.project(cursorLatlng, dotLayer._tileZoom).floor()
        const tileCoordinates = point.unscaleBy(tileSize).floor()
        tileCoordinates.z = dotLayer._tileZoom
        const offset = point.subtract(tileCoordinates.scaleBy(tileSize))

        const tile = dotLayer._tiles[dotLayer._tileCoordsToKey(tileCoordinates)]
        if (!tile || !tile.loaded) {
            return null
        }

        try {
            const canvas = document.createElement('canvas')
            canvas.width = tileSize.x
            canvas.height = tileSize.y

            const context = canvas.getContext('2d')
            context.drawImage(tile.el, 0, 0, tileSize.x, tileSize.y)
            const windowOrigin = L.point(offset.x - windowSize / 2, offset.y - windowSize / 2)
            const imageData = context.getImageData(windowOrigin.x, windowOrigin.y, windowSize, windowSize).data

            const relativeDotOffset = this.findOffsetOfClosestDot(imageData, windowSize)
            if (relativeDotOffset) {
                const dotOffset = windowOrigin.add(relativeDotOffset)
                return { tileCoordinates, dotOffset }
            } else {
                return null
            }
        } catch (e) {
            console.error(e)
            return null
        }
    }

    findOffsetOfClosestDot(imageData, windowSize) {
        const center = L.point(windowSize / 2, windowSize / 2)
        let closestDot = null
        for (let y = 0; y < windowSize; y++) {
            for (let x = 0; x < windowSize; x++) {
                const i = y * (windowSize * 4) + x * 4 + 3
                if (imageData[i] > 100) {
                    const pixelCoordinates = L.point(x, y)
                    const distance = pixelCoordinates.distanceTo(center)
                    if (!closestDot || closestDot.distance > distance) {
                        const offset = L.point(x, y)
                        closestDot = { offset, distance }
                    }
                }
            }
        }
        return closestDot && closestDot.offset
    }

    onMapClick = e => {
        const cursorLatlng = e.latlng
        const map = e.target
        const windowSize = 8
        const dotCoordinates = this.getDotCoordinates(cursorLatlng, map, windowSize)
        this.props.onMapClick(dotCoordinates, cursorLatlng)
    }

    setCursor(visible) {
        const mapContainer = this.mapRef.current.leafletElement._container
        if (visible) {
            if (!mapContainer.className.includes('pointer-cursor')) {
                mapContainer.className += ' pointer-cursor'
            }
        } else {
            if (mapContainer.className.includes('pointer-cursor')) {
                mapContainer.className = mapContainer.className.replace(' pointer-cursor', '')
            }
        }
    }

    onMouseMove = e => {
        const windowSize = 8
        const dotCoordinates = this.getDotCoordinates(e.latlng, e.target, windowSize)
        this.setCursor(dotCoordinates != null)
    }

    render() {
        const {
            attribution,
            initialLocation,
            currentLocation: { zoom },
            selectedFeatureId,
            selectedFeatureVisible,
            onMarkerClick,
            onBoundsChange,
        } = this.props

        const { nonOverlappingFeatures, selectedLayer } = this.state

        return (
            <LeafletMap
                ref={this.mapRef}
                center={initialLocation.center}
                zoom={initialLocation.zoom}
                zoomControl={false}
                style={{ height: '100%' }}
                onclick={this.onMapClick}
                maxZoom={22}
                minZoom={8}
                maxBounds={switzerlandBounds}
                whenReady={e => onBoundsChange(e.target)}
                onmoveend={e => onBoundsChange(e.target)}
                onmousemove={this.onMouseMove}
            >
                {selectedLayer === 0 && (
                    <TileLayer
                        attribution={`© MapTiler ${attribution}`}
                        url="https://api.maptiler.com/maps/positron/256/{z}/{x}/{y}.png?key=JRO3uOaitfawlrCKgZWE"
                        zIndex={1}
                    />
                )}

                {selectedLayer === 1 && (
                    <TileLayer
                        attribution={attribution}
                        url="https://tile.osm.ch/osm-swiss-style/{z}/{x}/{y}.png"
                        zIndex={2}
                    />
                )}

                {zoom < 19 && (
                    <TileLayer
                        url="https://castle-miniwfs.geoh.infs.ch/tiles/castles/{z}/{x}/{y}.png"
                        crossOrigin="anonymous"
                        zIndex={3}
                    />
                )}

                <DumbControl position="bottomleft">
                    <a href='https://www.maptiler.com/' target="_">
                        <MaptilerLogo
                            style={{ maxWidth: '100px', maxHeight: '40px', paddingLeft: '5px', paddingRight: '5px' }}
                        />
                    </a>
                </DumbControl>
                <ZoomControl position="topleft" />
                <LayerSelectionControl
                    titles={['MapTiler Positron', 'OSM Swiss-Style']}
                    images={[PreviewMapTilerPositron, PreviewOSMSwissStyle]}
                    selectedIndex={selectedLayer}
                    onSelectLayer={selectedLayer => this.setState({ selectedLayer })}
                />

                {
                    nonOverlappingFeatures.map((feature, index) => {
                        if (feature.geometry.type !== 'Point') {
                            return null
                        }

                        const isSelected =
                            selectedFeatureVisible && selectedFeatureId != null && selectedFeatureId === feature.id
                        return (
                            <CastleMarker
                                key={`marker-${index}`}
                                feature={feature}
                                onClick={() => onMarkerClick(feature)}
                                isSelected={isSelected}
                            />
                        )
                    })
                }
            </LeafletMap >
        )
    }
}
